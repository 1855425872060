/**
 * @author Tornike Jortmenadze (Jortsoft)
 */

/**
 * REQUIRED EVENTS
 * @mouseenter="scrollStatus(true, 'element-class')"
 * @mouseleave="scrollStatus(false, 'element-class')" 
 * @summary Handle inner element scroll when Locomotive Scroll is on.
 */

// IMPORTS
import { useGlobalStore } from "@/store/pinia/global";
import { handleResize } from "@/composables/resizeHandler";


// DEFINE STORE
const store = useGlobalStore();

// COMPOSABLES
const { windowWidth } = handleResize();

export const scrollStatus = (val: boolean) => {
    if (windowWidth.value < 991) return
    store.disableScrollStatus = val;
};

